<template>
  <v-container id="owner-detail" fluid tag="section">
    <v-row justify="center">
      <dialog-message
        :dialog_message="dialog_message"
        :message="message"
      ></dialog-message>
      <v-col cols="12" md="10">
        <base-material-card>
          <template v-slot:heading>
            <div
              class="font-weight-light text-center text-h3 font-weight-medium"
              v-if="!createCommmonPart"
            >
              {{ $t("common_part.updateCommonPart") }}
            </div>
            <div
              class="font-weight-light text-center text-h3 font-weight-medium"
              v-if="createCommmonPart"
            >
              {{ $t("common_part.createCommonPart") }}
            </div>
          </template>

          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="4" v-if="createCommmonPart">
                  <v-select
                    :label="$t('urbanRenewal.city_name')"
                    :items="city_info"
                    v-model="city_code"
                    v-on:change="getDistrictsApi"
                    :rules="fieldRules"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" v-if="createCommmonPart">
                  <v-select
                    :label="$t('urbanRenewal.area_name')"
                    :items="district_info"
                    v-model="district_code"
                    @change="getRegionApi"
                    :item-text="(item) => item.name"
                    item-value="code"
                    :rules="fieldRules"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4" v-if="createCommmonPart">
                  <v-select
                    :label="$t('urbanRenewal.region_name')"
                    :items="region_info"
                    v-model="region_code"
                    :item-text="getRegionDisplay"
                    item-value="code"
                    :rules="fieldRules"
                    @change="setRegionId"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" v-if="createCommmonPart">
                  <v-row>
                    <v-col cols="6" class="py-0 ml-2">
                      <v-text-field
                        :label="$t('owner.buildingMajorNumber')"
                        v-model="buildingMajorNumber"
                        :rules="fieldRules"
                      />
                    </v-col>
                    <v-col cols="5" class="py-0">
                      <v-text-field
                        prepend-icon="mdi-minus"
                        :label="$t('owner.buildingMinorNumber')"
                        v-model="buildingMinorNumber"
                        :rules="fieldRules"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4" v-if="!createCommmonPart">
                    <v-text-field
                        class="mx-2"
                        :value="getBuildingName(item)"
                        :rules="fieldRules"
                        readonly
                        :label="$t('building.building_no')"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    class="mx-2"
                    v-model="item.building_area"
                    :rules="fieldRules"
                    type="number"
                    :label="$t('building.building_area')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select
                    class="mx-2"
                    :label="$t('common_part.building_info')"
                    :items="building_infos"
                    v-model="item.building_info_id"
                    :rules="fieldRules"
                    :item-text="getBuildingName"
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    class="mx-2"
                    v-model="item.building_numerator"
                    :rules="fieldRules"
                    type="number"
                    :label="$t('owner.numerator')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    class="mx-2"
                    v-model="item.building_denominator"
                    :rules="fieldRules"
                    type="number"
                    :label="$t('owner.denominator')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="8" class="text-right">
                  <v-btn color="aqua" class="mx-2" @click="back(urban)">{{
                    $t("common.back")
                  }}</v-btn>
                  <v-btn
                    v-if="!createCommmonPart"
                    color="success"
                    class="mr-0"
                    @click="patchApi()"
                    >{{ $t("common.save") }}</v-btn
                  >
                  <v-btn
                    v-if="createCommmonPart"
                    :disabled="loading"
                    color="success"
                    class="mr-0"
                    @click="save()"
                    >{{ $t("common.save") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data: (vm) => ({
    dialog_message: false,
    valid: true,
    loading: false,
    createCommmonPart: false,
    item: {
      urban_renewal: null,
      region: null,
      building_no: null,
      building_info: null,
      building_area: null,
      disabled: false,
      building_numerator: null,
      building_denominator: null,
    },
    fieldRules: [(v) => !!v || vm.$i18n.t("common.missing")],
    message: {
      title: "",
      text: "",
    },
    city_info: [],
    district_info: [],
    region_info: [],
    district_list: [],
    region_list: [],
    city_code: null,
    district_code: null,
    region_code: null,
    buildingMajorNumber: null,
    buildingMinorNumber: null,
    building_infos: [],
  }),
  computed: {
    ...mapState(["token"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getApi() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/commpnPart/" +
        this.item_id +
        "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.item = response.data.commonpart;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    getLBinfo() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/urban/" +
        this.urban +
        "/landNo/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.item.building_no = this.city_code+"_"+this.district_code+"_"+this.region_code+"_"+this.buildingMajorNumber+"-"+this.buildingMinorNumber;
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/commpnPart/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token,
        },
      };
      let data = {
        urban_renewal_id: this.urban,
        region_id: this.item.region,
        building_no: this.item.building_no,
        building_info_id: this.item.building_info_id,
        building_area: this.item.building_area,
        disabled: this.item.disabled,
        building_numerator: this.item.building_numerator,
        building_denominator: this.item.building_denominator,
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.back(currentObj.urban);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("save-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    patchApi() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/commpnPart/" +
        this.item.id +
        "/";

      const jsonData = {
        urban_renewal_id: this.urban,
        region_id: this.item.region.id,
        building_no: this.item.building_no,
        building_info_id: this.item.building_info_id,
        building_area: this.item.building_area,
        disabled: this.item.disabled,
        building_numerator: this.item.building_numerator,
        building_denominator: this.item.building_denominator,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          alert("更新成功。");
          currentObj.back(currentObj.urban);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("update-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
      this.dialog_form = false;
    },
    setResult(data) {
      this.building_infos = data.building_info;
      this.land_infos = data.land_info
      if(!this.createCommmonPart){
        this.item.building_info_id = this.item.building_info.id
      }
      this.setCDRlist()
    },
    setCDRlist(){
      this.city_info = []
      this.district_list = []
      this.region_list = []
      this.land_infos.forEach(element=>{
        let city_code = element.region.city.code
        let city_item = {
          'text': element.region.city.name,
          'value': city_code
        }
        this.city_info.push(city_item)
        this.district_list.push(element.region.district)
        this.region_list.push(element.region)
      })
    },
    getDistrictsApi() {
      this.district_info = [];
      this.region_info = [];
      this.district_list.forEach(element=>{
        if(element.city.code == this.city_code){
          this.district_info.push(element)
        }
      })
    },
    getRegionApi() {
      this.region_info = [];
      this.region_list.forEach(element=>{
        if(element.city.code == this.city_code && element.district.code == this.district_code){
          this.region_info.push(element)
        }
      })
    },
    getRegionDisplay(item) {
      return item.name
    },
    getBuildingName(item) {
      if(item.region == null){
        return null
      }
      return (
        item.region.city.name +
        item.region.district.name +
        item.region.name +
        item.building_no.split("_").pop()
      );
    },
    setRegionId(item){
        let region = this.region_info.find(element=>{return element.code == item})
        this.item.region = region.id
    },
    back(item) {
      this.$router.push({
        name: "CommonPartList",
        query: {
          item: item,
        },
      });
    },
  },
  mounted() {
    this.setTitle(this.$i18n.t("title.CommonPart"));
    this.urban = this.$route.query.urban;
    if (this.$route.query.item) {
      this.item_id = this.$route.query.item;
      this.getApi();
    } else {
      this.createCommmonPart = true;
      this.getLBinfo();
    }
  },
};
</script>